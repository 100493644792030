import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'gatsby';
import React from 'react';
import tw, { css, styled } from 'twin.macro';

const StyledButton = styled(ButtonBase)`
  ${props => css`
    &:focus {
      outline: none;
      opacity: 0.9;
    }
  ;

    &:disabled {
      ${tw`bg-grey-F text-grey-7`}
    }

    ${tw`relative flex items-center justify-center`};

    ${props.mode === 'main' && tw`text-08 text-light font-bold mx-04 rounded-05 px-12 py-06 bg-grey-C`}
    ${props.mode === 'primary' && tw`text-light mx-05 rounded-05 px-12 py-03 bg-green-1`}
    ${props.mode === 'primary-auto' && tw`text-light mx-auto rounded-05 text-center px-12 py-03 bg-green-1`}
    ${props.mode === 'delete' && tw`text-light mx-05 rounded-05 px-12 py-03 bg-red-0`}
    ${props.mode === 'outline' && tw`text-grey-5 mx-05 rounded-05 px-10 py-02 bg-light border-2 border-solid border-green-5`}
    ${props.mode === 'common' && tw`bg-grey-F border border-solid border-grey-B text-grey-4`}
		
    ${props.mode === 'filter' && tw`w-70 rounded-05 px-10 py-02 bg-light text-grey-7 border border-solid border-grey-7 mx-02`}

    ${props.size === 'large' && tw`text-11 rounded-15`}
    ${props.mode === 'outline' && props.size === 'large' && tw`py-04 px-28`}
    ${props.mode !== 'outline' && props.size === 'large' && tw`py-05 px-30`}

    ${props.size === 'medium' && tw`text-09 rounded-10`}
    ${props.mode === 'outline' && props.size === 'medium' && tw`py-02 px-09`}
    ${props.mode !== 'outline' && props.size === 'medium' && tw`py-03 px-10`}

    ${props.size === 'small' && tw`text-08 rounded-10`}
    ${props.mode === 'outline' && props.size === 'small' && tw`py-02 px-07`}
    ${props.mode !== 'outline' && props.size === 'small' && tw`py-03 px-08`}

    ${props.size === 'smaller' && tw`text-07 rounded-10`}
    ${props.mode === 'outline' && props.size === 'smaller' && tw`py-02 px-04`}
    ${props.mode !== 'outline' && props.size === 'smaller' && tw`py-02 px-05`}

    ${props.size === 'smallest' && tw`text-07 rounded-03 mx-03`}
    ${props.mode === 'outline' && props.size === 'smallest' && tw`py-px px-03`}
    ${props.mode !== 'outline' && props.size === 'smallest' && tw`py-px px-04`} // Finteq
    ${props.mode === 'icon-left' && tw`w-120 text-11 text-light rounded-20 p-03 bg-green-1`}
  `};
`;


export default function Button({ loading, focusOnMount, mode, size, type, children, sizeCircularProgress, onClick, ...props }) {
	const isLink = !onClick && type !== 'submit';

	const handleClick = event => {
		!loading && onClick && onClick(event);
	};

	return (
		<StyledButton
			ref={input => focusOnMount && input && input.focus()}
			component={isLink ? Link : null}
			onClick={handleClick}
			mode={mode}
			size={size}
			type={type}
			{...props}
		>
			{loading && <CircularProgress tw="text-grey-D absolute" size={sizeCircularProgress}/>}
			<div css={[tw`w-full`, loading && tw`opacity-0`]}>{children}</div>
		</StyledButton>
	);
}

Button.defaultProps = {
	sizeCircularProgress: 10,
	mode: 'primary',
	type: 'button',
	onClick: undefined
};
